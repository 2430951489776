import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Leaflet from "leaflet";
import styled from "styled-components";
import marker from "../images/marker-icon.png";
import { LeafletStyles } from "../styles/leaflet.styles";

const Map: React.FC = () => {
  if (typeof window !== "undefined") {
    const markerIcon = new Leaflet.Icon({
      iconUrl: marker,
      iconSize: [25, 34],
      iconAnchor: [12, 34],
      popupAnchor: [0, -35],
    });

    const HQ: Leaflet.LatLngExpression = [
      47.10078168580062, 17.928184068347612,
    ];
    const LAB: Leaflet.LatLngExpression = [
      47.48000392367997, 19.057953019993764,
    ];
    const MARINA: Leaflet.LatLngExpression = [
      46.94060175103276, 17.869605538124507,
    ];

    return (
      <LeafletStyles>
        <Wrapper>
          <MapContainer
            style={{ width: "100%", height: "100%" }}
            center={HQ}
            zoom={7}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution={
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              }
              url={
                "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              }
            />

            <Marker position={HQ} icon={!!markerIcon ? markerIcon : undefined}>
              <Popup>
                Fastrrr
                <br />
                Headquarters
              </Popup>
            </Marker>
            <Marker position={LAB} icon={!!markerIcon ? markerIcon : undefined}>
              <Popup>
                Fastrrr
                <br />
                Lab
              </Popup>
            </Marker>
            <Marker
              position={MARINA}
              icon={!!markerIcon ? markerIcon : undefined}
            >
              <Popup>
                Fastrrr
                <br />
                Marina Base
              </Popup>
            </Marker>
          </MapContainer>
          <Overlay />
        </Wrapper>
      </LeafletStyles>
    );
  } else {
    return <div>Error loading map</div>;
  }
};

export default Map;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;

  a {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Overlay = styled.div`
  pointer-events: none;
  position: absolute;
  inset: 0;
  z-index: 999;
`;
