import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import Layout from "../components/layout";
import Map from "../components/map";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { Button } from "../styles/ui.styles";
import { FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";

import axios from "axios";

const Contact: React.FC = () => {
  const [status, setStatus] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const fields = watch();
  const onSubmit: SubmitHandler<FormData> = () => {
    const contactFormData = new FormData();
    contactFormData.append("name", fields["name"]);
    contactFormData.append("email", fields["email"]);
    contactFormData.append("message", fields["message"]);
    axios({
      method: "post",
      url: `${process.env.GATSBY_SITE_URL}/api/send-contact-message.php`,
      data: contactFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log(res);
        if (res.data === "success") {
          reset({ name: "", email: "", message: "" });
          setStatus("Your message has been sent!");
        }
      })
      .catch((error) => {
        console.log("Error sending contact form, ", error);
        setStatus(
          `Error sending message.
           Please write an email to info@fastrrr.com instead.`
        );
      });
  };

  React.useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <>
      <SEO title={"Contact"} description={"Contact"} />
      <Layout allowTransparentHeader={true}>
        <ImgContainer>
          <StaticImage
            style={{ width: "100%", height: "100%" }}
            src={"../images/contact.jpg"}
            alt={"Contact image"}
          />
          <Overlay />
          <TitleWrapper>
            <h1>Contact</h1>
            <p>Get in touch with by completing the below form or call us now</p>
          </TitleWrapper>
        </ImgContainer>
        <ContactWrapper>
          <ContactFormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>Full name *</label>
              <input
                type="text"
                placeholder="Joe Smith"
                {...register("name", { required: true, maxLength: 100 })}
              />
              <label>Email *</label>
              <input
                type="email"
                placeholder="joe.smith@gmail.com"
                {...register("email", { required: true })}
              />
              <label>Message *</label>
              <textarea
                placeholder="Write your message here..."
                {...register("message", { required: true })}
              />
              <Button aria-label={"Submit"} type="submit" primary={true}>
                Send Message
              </Button>
            </form>
            <StatusWrapper>
              {status && (
                <p style={{ textAlign: "center", border: 0 }}>{status}</p>
              )}
              {errors?.name && (
                <p style={{ textAlign: "center", border: 0 }}>
                  Name is required!
                </p>
              )}
              {errors?.email && (
                <p style={{ textAlign: "center", border: 0 }}>
                  Email is required!
                </p>
              )}
              {errors?.message && (
                <p style={{ textAlign: "center", border: 0 }}>
                  Message is required!
                </p>
              )}
            </StatusWrapper>
          </ContactFormWrapper>
          <ContactDetailsWrapper>
            <div>
              <h4>Contact detalis</h4>
              <div className="verticalCenter">
                <FaWhatsapp />
                <p>
                  +36 70 <span>608</span> 0692
                </p>
              </div>
            </div>
            <div>
              <h4>Locations</h4>
              <h5>Veszprém – Headquater</h5>
              <div>
                <FaMapMarkerAlt />
                <p>
                  FASTRRR International Kft.
                  <br />
                  Hungary, Veszprém
                  <br />
                  8200
                  <br />
                  Bolgár Mihály utca 24.
                </p>
              </div>
            </div>
            <div>
              <h5>Budapest - Lab</h5>
              <div>
                <FaMapMarkerAlt />
                <p>
                  Budapesti Műszaki és Gazdaságtudományi Egyetem
                  <br />
                  Z building
                  <br />
                  Hungary, Budapest <br />
                  1111
                  <br />
                  Bertalan Lajos u. 2.
                  <br />
                  10th floor
                  <br />
                </p>
              </div>
            </div>
            <div>
              <h5>Balatonfüred – Marina base</h5>
              <div>
                <FaMapMarkerAlt />
                <p>
                  Harbor Shipyard
                  <br />
                  Hungary, Balatonfüred
                  <br />
                  8230
                  <br />
                  Tihanyi műút
                </p>
              </div>
            </div>
          </ContactDetailsWrapper>
        </ContactWrapper>
        <Map />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Contact;

const TitleWrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-top: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
`;

const ImgContainer = styled.div`
  height: 500px;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, var(--color-dark-blue) 100%),
    rgba(19, 14, 102, 0.46);
`;

const ContactWrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  display: grid;
  grid-template-columns: 600px 1fr;
  justify-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const ContactFormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  height: 720px;
  border-radius: 20px;
  box-shadow: var(--shadow);
  padding: 70px;
  background: #fff;
  transform: translate(0, -50px);

  form {
    display: flex;
    flex-direction: column;
  }
  input,
  textarea {
    width: 100%;
    border: 1px solid var(--color-orange-hovered);
    border-radius: 99px;
    padding-left: 2rem;

    &:focus {
      outline: none;
    }
    &:focus,
    &:hover {
      border-color: var(--color-orange);
    }
  }
  input {
    margin-bottom: 20px;
  }
  textarea {
    border-radius: 20px;
    resize: none;
    height: 200px;
    margin-bottom: 70px;
  }
  button {
    align-self: center;
    width: 250px;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
  }

  @media (max-width: 992px) {
    height: 660px;
    padding: 50px;
  }
  @media (max-width: 480px) {
    height: 600px;
    padding: 32px;
  }
  @media (max-width: 350px) {
    padding: 20px;
  }
`;
const ContactDetailsWrapper = styled.div`
  padding: 64px;
  width: 100%;

  /* columns: 2 380px;
  div {
    break-inside: avoid;
  } */

  div div {
    margin: 18px 0;
    display: flex;

    &.verticalCenter {
      align-items: center;
    }

    & svg {
      width: 30px;
      height: 30px;
      fill: var(--color-orange);
    }
    & p {
      flex: 1;
    }
  }

  h4 {
    margin: 0;
  }

  h5 {
    margin: 18px 0;
  }
  p {
    margin: 0;
    margin-left: 20px;
  }

  @media (max-width: 1200px) {
    padding: 32px;
  }
`;

const StatusWrapper = styled.div`
  p {
    margin: 0;
  }
  p:first-child {
    margin-top: 10px;
  }
`;
